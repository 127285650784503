* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
}

@keyframes float {
  0% {
    transform: translateY(0px);
  }
  50% {
    transform: translateY(-30px);
  }
  100% {
    transform: translateY(0px);
  }
}
.animation {
  animation: float 3s ease-in-out infinite;
}

.animation.a {
  animation-delay: 0.5s;
}
.animation.b {
  animation-delay: 1s;
}
.animation.c {
  animation-delay: 1.5s;
}
